import React, { useState, useEffect } from 'react'
import { NavBar, TabBar, Image, AutoCenter, Divider } from 'antd-mobile'
import {
    Route,
    useLocation,
    useNavigate,
    MemoryRouter as Router,
} from 'react-router-dom'
import {
    UserCircleOutline,
    AppstoreOutline
} from 'antd-mobile-icons'
import liff from '@line/liff';
import { ShopOutlined, ShoppingCartOutlined, HistoryOutlined, ContainerOutlined, PlusCircleOutlined, HomeOutlined } from '@ant-design/icons';
import { HistogramOutline } from 'antd-mobile-icons'
import styles from './mobileStyle.css';
import CartFloatButton from '../Components/CartFloatButton';


const Bottom = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { pathname } = location

    const setRouteActive = (value) => {
        navigate(value);
    }

    const tabs = [
        {
            key: '/home',
            title: 'หน้าหลัก',
            icon: <HomeOutlined />,
        },
        {
            key: '/store',
            title: 'ตลาดพันปืน',
            icon: <ShopOutlined />,
        },
        {
            key: '/products/create',
            title: 'ลงประกาศขาย',
            icon: <PlusCircleOutlined />,
        },
        {
            key: '/attedance',
            title: 'บัญชี',
            icon: <AppstoreOutline />,
        },
        {
            key: '/report',
            title: 'รายงาน',
            icon: <HistogramOutline />,
        },
    ]

    return (
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)} safeArea>
            {tabs.map(item => (
                <TabBar.Item className="custom-tab-item" key={item.key} icon={item.icon} title={item.title} style={{color:'black'}}/>
            ))}
        </TabBar>
    )
}


export default ({ children, cartData, onUpdateCart, onDelete, setCartData, rerenderKey }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [isOwner, setOwnerId] = useState(false);
    const [isLoading, setIsloading] = useState(true);

    return (
        <>
            <div className={'app'} style={{ height: '100%' }}>
                <CartFloatButton />
                <div className={'top'} style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1, background: '#007bff', color:'black' }}>
                    <NavBar onBack={() => navigate(-1)} style={{ background: 'yellow' }}></NavBar>
                </div>
                <div className={'body'}>
                    {children}
                </div>
                <div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 1, background: 'yellow', color:'black' }}>
                    <Bottom />
                </div>
            </div>
        </>
    )
}