import React, { useState, useEffect } from 'react';
import { Spin, message, Button, Input, Form, Flex, Radio, } from 'antd';
import { Dialog } from 'antd-mobile';
import axios from 'axios';

const { Item } = Form;

const RegistrationForm = ({ onSuccess, userLineID }) => {

  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Make a PUT request to update the employee details API
      const response = await axios.put(`https://pos-time-attendance-be.vdqx78.easypanel.host/api/employees/${userLineID}`, { first_name: values.name, last_name: values.lastName, phone: values.phone, department: values.department });
      //console.log('response: ' + JSON.stringify(response));
      // Show success message
      Dialog.alert({
        content: 'แก้ไขข้อมูลเรียบร้อย',
        closeOnMaskClick: true,
        confirmText: 'รับทราบ'
      })

      const response2 = await axios.get(`https://pos-time-attendance-be.vdqx78.easypanel.host/api/employees/${userLineID}`);
      const { first_name, last_name, department, phone } = response2.data;
      //console.log('employee details: ', first_name, last_name, department, phone);
      setProfile({ name: first_name, lastName: last_name, department: department, phone: phone });
      setLoading(false);

      if (onSuccess) onSuccess(); // Callback function if provided

    } catch (error) {
      // Show error message
      console.error('Error updating employee:', error);
      message.error('Failed to update employee details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  //useEffect
  useEffect(() => {

    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get(`https://pos-time-attendance-be.vdqx78.easypanel.host/api/employees/${userLineID}`);
        const { first_name, last_name, department, phone } = response.data;
        //console.log('employee details: ', first_name, last_name, department, phone);
        setProfile({ name: first_name, lastName: last_name, department: department, phone: phone });
        setLoading(false);

      } catch (error) {
        console.error('Error fetching employee details:', error);
      }
    };

    fetchEmployeeDetails();

  }, [userLineID]);

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };

  const content = <div style={contentStyle} />;

  if (loading) {

    return (
      <>
        <Spin tip="กรุณารอสักครู่...">{content}</Spin>
      </>
    )
  }

  return (
    <div style={{ maxWidth: '100vh', margin: '0', padding: '0px' }}>
      <h2 style={{ textAlign: 'center', fontFamily: 'Prompt' }}>แก้ไขข้อมูลส่วนตัว</h2>
      <Spin spinning={loading}>
        {profile && (
          <>
            <Form
              layout="vertical"
              onFinish={onFinish}
              initialValues={profile}
              style={{ textAlign: 'center', fontFamily: 'Prompt', color: 'white' }}
            >
              {/* <Item
            name="userLineID"
            label="LINE User ID"
            rules={[{ required: true, message: 'Please enter your LINE User ID!' }]}
            style={{ visibility: 'hidden' }}
            readOnly={true}
          >
            <Input defaultValue={userLineID} readOnly style={{ fontFamily: 'Prompt' }} />
          </Item> */}
              <Item
                name="department"
                label="สังกัด"
                rules={[{ required: true, message: 'กรุณาเลือกกองร้อย!!!' }]}
                style={{ textAlign: 'center', fontFamily: 'Prompt', color: 'black' }}
              >
                <Radio.Group defaultValue="ร้อย.บก." buttonStyle="solid">
                  <Radio.Button value="ร้อย.บก.">บก.</Radio.Button>
                  <Radio.Button value="ร้อย.1">ร้อย.1</Radio.Button>
                  <Radio.Button value="ร้อย.2">ร้อย.2</Radio.Button>
                  <Radio.Button value="ร้อย.3">ร้อย.3</Radio.Button>
                </Radio.Group>
              </Item>
              <Item
                name="name"
                label="ยศ-ชื่อ"
                rules={[{ required: true, message: 'กรุณาป้อนชื่อ!' }]}
              >
                <Input />
              </Item>
              <Item
                name="lastName"
                label="นามสกุล"
                rules={[{ required: true, message: 'กรุณาป้อนนามสกุล!' }]}
              >
                <Input />
              </Item>
              <Item
                name="phone"
                label="เบอร์โทร"
                rules={[{ required: false, message: 'กรุณาป้อนเบอร์โทรศัพท์ที่ติดต่อได้!' }]}
              >
                <Input />
              </Item>
              <Item>
                <Button type="primary" htmlType="submit" block style={{ fontFamily: 'Prompt' }}>
                  บันทึก
                </Button>
              </Item>
            </Form>

          </>
        )}
      </Spin>
    </div>
  );
};

export default RegistrationForm;