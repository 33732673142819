// src/App.js
import React from 'react';
import AppRoutes from './Routes';
import { ConfigProvider, theme } from 'antd';

const App = () => {
  return (
    <ConfigProvider theme={{
      token: {
        fontFamily: "Prompt",
        color: 'white',
        // Seed Token
        colorPrimary: '#00b96b',
        borderRadius: 2,
        // Alias Token
        colorBgContainer: '#f6ffed',
        // 1. Use dark algorithm
        algorithm: theme.darkAlgorithm,
      }
    }}>
      <AppRoutes />
    </ConfigProvider>
  );
};

export default App;
